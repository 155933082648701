import React, { useEffect, useState, useRef } from 'react'
import { DataCollectionModal, DataCollectionState } from '../add-new-data-collection/new-data-collection-state';
import * as collectionService from '../../../services/data-collection.service';
import * as connectionService from '../../../services/data-source.service';
import BasicInformationController from '../add-new-data-collection/basic-information/basic-information-controller';
import ColumnSelectionPopup from '../../../components/data-collections/column-selection-popup/column-selection-popup';
import { AppEnum } from '../../../constants/app-enum';
import _ from 'lodash';
import { RestServiceConstants } from '../../../constants/rest-service-constant';
import { toast } from 'react-toastify';
import { DynamicList } from '../../connections/variables/variables-Popup';
import { UpdateUserModel } from '../../connections/variables/variables-Popup';
import { authorizeAgain, getToken } from '../../../services/authorize-again-service';
import { checkOtherParams, filterCollectionParams } from '../DataVisualizer/data-visualizer-controller';
import { DateParseVariable } from '../../../constants/date-parse-variable';

export default function NativeDataVisualizerController(props){

    const [state, setState] = useState(new DataCollectionState());
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [isOpenInDialog,setIsOpenInDialog] = useState(props.showNativeDataVisualizer);
    const [selectedSearchButton, setSelectedSearchButton] = useState(0);
    const [isSearchOptionsAvailable, setIsSearchOptionsAvailable] = useState(false);
    const [searchOptionsParameters, setSearchOptionsParameters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columnsList, setColumnList] = useState([]);
    const [isColumnOrderChanged, setIsColumnOrderChanged] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [preview, setPreview] = useState(false);
    const searchInputRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [filterColumnsList, setFilterColumnsList] = useState([]);
    const [openConnectionPopup, setOpenConnectionPopup] = useState(false);
    const [stateConnectionParameter, setStateConnectionParameter] = useState(false);
    const [isAuthorizeAgain, setIsAuthorizeAgain] = useState(false);
    const [nativeCollectionParameterResponse,SetNativeCollectionParameterResponse] = useState(false);
    const [restSchemaList,setRestSchemaList] = useState([]);
    const [isRestSchemaAvailable,setIsRestSchemaAvailable] = useState(false);
    const [isDynamicListValues, setIsDynamicListValues] = useState(
        new DynamicList()
      );
    const [variableUId,setVariableUId] = useState("");
    const [isVariableUsedInCollection,setIsVariableUsedInCollection] = useState(false);
    const [manualColumns,setManualColumns] = useState([]);
    const dateParser = new DateParseVariable();

    useEffect(()=>{
        setIsLoading(true);
        getCollectionDetails(props.collectionUId);
    },[])

    useEffect(() => {
        if (state?.connectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
            getDisplayFormatList();
        }
    }, [state?.connectionUId])


    useEffect(()=>{
        if(selectedConnection != null && state?.sysCollectionTypeId === AppEnum.SysCollectionTypeId.REST){
            executeData();
        }
    },[selectedConnection])

    useEffect(() =>{
        if(nativeCollectionParameterResponse){
            if(state?.sysCollectionTypeId !== AppEnum.SysCollectionTypeId.REST){
                getColumnSchema(state,formatColumList);
            
            }
        }
    },[state?.uId, nativeCollectionParameterResponse]);

    useEffect(() => {
        if (state?.collectionColumnSchema && state?.collectionColumnSchema.length > 0 && !isColumnOrderChanged && state?.sysCollectionTypeId !== AppEnum.SysCollectionTypeId.REST) {
            executeData(false);
        }
    }, [columnsList])

    useEffect(()=>{
        if(isRestSchemaAvailable){
            formatColumList(restSchemaList);
        }
    },[isRestSchemaAvailable])

    useEffect(() => {
            dataAfterTransformation(state?.testedDataList);
    }, [state?.testedDataList])

    useEffect(() => {

        if (state?.collectionColumns.length > 0 && props.collectionUId && state.testedDataTransformedColumneKeys.length === 0) {
            const updatedColumns = state?.collectionColumns.map(column => ({
                ...column,
                ColumnDataType: state?.displayFormatList?.filter(i => i.sysDataTypeId === column?.dataType)[0]?.dataBaseDataType,
                isSelected: true
            }));

            setState(prevState => ({
                ...prevState,
                columnSchemaList: updatedColumns
            }));
        }

        //removing colunms whic are not present in table after transformation on update.
        if (state?.columnSchemaList && state?.collectionColumns && props.collectionUId && state?.testedDataTransformedColumneKeys.length > 0) {
            const filteredColumnsList = state?.columnSchemaList.filter(column => (state?.testedDataTransformedColumneKeys.includes(column.columnName) || column?.isManuallyCreated));
            const filteredColumns = state?.collectionColumns.filter(column => (state?.testedDataTransformedColumneKeys.includes(column.columnName) || column?.isManuallyCreated));
            setState((prevState) => { return { ...prevState, columnSchemaList: filteredColumnsList } });
            setState((prevState) => { return { ...prevState, collectionColumns: filteredColumns } });
            if(state?.sysCollectionTypeId === AppEnum.SysCollectionTypeId.REST){
                setIsRestSchemaAvailable(true);
            }
            
        }
    }, [state?.testedDataTransformedColumneKeys]);


    const getCollectionDetails = (collectionUId) => {

        collectionService.getCollectionDetails(collectionUId)
            .then((response) => {
                if (response?.data) {
                    let prevCollection = response?.data;
                    if (prevCollection) {

                        if (
                            prevCollection.isCollectionAsDataSource &&
                            prevCollection?.variable != null
                          ) {
                            setVariableUId(prevCollection?.variable?.uId);
                            setIsVariableUsedInCollection(prevCollection?.variable?.isUsedInCollection);
                            const parsedData = JSON.parse(
                              prevCollection?.variable?.complexTypeValue
                            );
                            setIsDynamicListValues(parsedData);                            
                        }

                        setState((prevState) => {

                            return {
                                ...prevState,
                                connectionUId: prevCollection.connectionUId,
                                uId: prevCollection.uId,
                                sysCollectionTypeId: prevCollection.sysCollectionTypeId,
                                sysConnectionTypeId: prevCollection.sysConnectionTypeId,
                                sysCollectionSubTypeId: prevCollection.sysCollectionSubTypeId,
                                childGroupName: prevCollection.childGroupName,
                                groupName: prevCollection.groupName,
                                sourceName: prevCollection.sourceName,
                                sourceNamePart2: prevCollection.sourceNamePart2,
                                sourceNamePart4: prevCollection.sourceNamePart4,
                                isCollectionAsDataSource: prevCollection.isCollectionAsDataSource,
                                hasPrintableCollection: prevCollection.hasPrintableCollection,
                                collectionColumns: prevCollection.collectionColumns,
                                collectionParameters: prevCollection.collectionParameters,
                                collectionFilterColumns: prevCollection.collectionFilterColumns,
                                name: prevCollection.name,
                                categoryUId: prevCollection.categoryUId,
                                restSendAsCD: prevCollection.restSendAsCD,
                                restDataTransformationScript: prevCollection.restDataTransformationScript,
                                restBody: prevCollection.restBody,
                                restRequestMethod: prevCollection.restRequestMethod,
                                prevCollection: prevCollection,
                                basicInfoError: null,
                                templateCollectionId: prevCollection.templateCollectionId,
                                isFilterSupported: prevCollection.isFilterSupported,
                                isSortSupported: prevCollection.isSortSupported,
                                isPagingSupported: prevCollection.isPagingSupported,
                                areAllFieldsFilterable: prevCollection.areAllFieldsFilterable,
                                areAllFieldsSortable: prevCollection.areAllFieldsSortable,
                                columnApiEndpoint: prevCollection.columnApiEndpoint,
                                variableId: prevCollection.variableId,
                                returnRawData: prevCollection.returnRawData
                            }
                        })

                        const manuallyCreatedItems = prevCollection?.collectionColumns.filter(column => column.isManuallyCreated);

                        if(manuallyCreatedItems.length > 0){
                            setManualColumns(manuallyCreatedItems);
                        }

                        if(prevCollection?.restDataTransformationScript){
                            setState((prevState) => { return { ...prevState, restDataTransformationScript: prevCollection?.restDataTransformationScript } });
                        }

                        if (prevCollection.collectionParameters?.length > 0){
                            const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);

                              if (hasOtherParams){
                                prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                                let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                setIsSearchOptionsAvailable(true);                                
                                setSearchOptionsParameters(updatedParams);
                              }
                              else{
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                              }
                        }
                        else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }

                        SetNativeCollectionParameterResponse(true);
  
                        if (prevCollection.isTemplateCollection && prevCollection.isInheritCollection) {
                            setState((prevState) => { return { ...prevState, uId: null } });
                        }
                        getCollectionTypeList(prevCollection.sysCollectionTypeId);
                    }
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }

    const getColumnSchema = async (prevCollection, callback = null) => {
        setIsLoading(true);

        let dataCollectionModal;

        if (prevCollection) {
            dataCollectionModal = getDataCollectionModal(prevCollection);
        }
        else {
            dataCollectionModal = getDataCollectionModal(null);
        }

        const response = await collectionService.getColumnSchema(dataCollectionModal);

        if (response) {
            if (response?.hasError) {
                setState((prevState) => { return { ...prevState, columnSchemaError: response?.errorMessage, columnSchemaList: [] } });
                setIsLoading(false);
            }
            else {
                setState((prevState) => { return { ...prevState, collectionColumnSchema: response?.data?.data } });

                if (callback != null) {
                    callback(response?.data?.data);
                }
                setIsLoading(false);
            }
        }
    }

    const formatColumList = (data) => {
        if (data) {
            var columns = data?.map((i) => {
                let columnMap = state?.collectionColumns?.find(c => c.columnName == i.columnName);
                if (!columnMap){
                    return undefined;
                }
                return {
                    columnName: columnMap?.columnName ? columnMap?.columnName : i.columnName ,
                    displayName: columnMap?.displayName ? columnMap?.displayName : i.displayName,
                    isFilterable: columnMap?.isFilterable ? columnMap?.isFilterable : i.isFilterable,
                    isSortable:  columnMap?.isSortable ? columnMap?.isSortable : i.isSortable,
                    isDefault:  columnMap?.isDefault ? columnMap?.isDefault : i.isDefault,
                    isSelected: state?.collectionColumns?.find(c => c.columnName == i.columnName) ? true : false,
                    dataTypeName:  columnMap?.dataTypeName ? columnMap?.dataTypeName : i.columnDataType,
                    isMultiSelect:  columnMap?.isMultiSelect ? columnMap?.isMultiSelect : i.isMultiSelect,
                    options: columnMap?.options ? columnMap?.options : i.options,
                    notQuerable:  columnMap?.notQuerable ? columnMap?.notQuerable : i.notQuerable,
                    displayOrder : state?.collectionColumns?.length > 0 ? state?.collectionColumns?.find(c => c.columnName == i.columnName)?.displayOrder : 0
                }
            })?.filter(Boolean); 
            setState((prevState) => { return { ...prevState, collectionColumnSchema: columns } });
            const sortedList = _.orderBy(columns,['isSelected', 'displayName'],['desc', 'asc']);

            setIsColumnOrderChanged(false);
            setColumnList(sortedList);
        }
        return [];
    }


    const getDataCollectionModal = (prevCollection) => {
        let dataCollectionModal = new DataCollectionModal();
        let collection;
        if (prevCollection)
            collection = prevCollection;
        else
            collection = state;

        dataCollectionModal.connectionUId = collection.connectionUId;
        dataCollectionModal.groupName = collection.groupName;
        dataCollectionModal.childGroupName = collection.childGroupName;
        dataCollectionModal.name = collection.name;
        dataCollectionModal.sysCollectionTypeId = collection.sysCollectionTypeId;
        dataCollectionModal.sysConnectionTypeId = collection.sysConnectionTypeId;
        dataCollectionModal.sysCollectionSubTypeId = collection.sysCollectionSubTypeId;
        dataCollectionModal.sourceName = collection.sourceName;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.sourceNamePart4 = collection.sourceNamePart4;
        dataCollectionModal.categoryUId = collection.categoryUId;
        dataCollectionModal.columnSchema = collection.columnSchema;
        dataCollectionModal.isCollectionAsDataSource = collection.isCollectionAsDataSource;
        dataCollectionModal.hasPrintableCollection = collection.hasPrintableCollection;
        dataCollectionModal.collectionParameters = collection.collectionParameters;
        dataCollectionModal.collectionFilterColumns = collection.collectionFilterColumns;
        dataCollectionModal.collectionColumns = collection.collectionColumns;
        dataCollectionModal.uId = collection.uId;
        dataCollectionModal.restRequestMethod = collection.restRequestMethod ? collection.restRequestMethod : state.restRequestMethod;
        dataCollectionModal.restBody = collection.restBody ? collection.restBody : state.restBody;
        dataCollectionModal.restSendAsCD = collection.restSendAsCD ? collection.restSendAsCD : state.restSendAsCD;
        dataCollectionModal.restDataTransformationScript = collection.restDataTransformationScript ? collection.restDataTransformationScript : state.restDataTransformationScript;
        dataCollectionModal.templateCollectionId = collection?.templateCollectionId;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.isFilterSupported = collection?.isFilterSupported;
        dataCollectionModal.isSortSupported = collection?.isSortSupported;
        dataCollectionModal.isPagingSupported = collection?.isPagingSupported;
        dataCollectionModal.areAllFieldsFilterable = collection?.areAllFieldsFilterable;
        dataCollectionModal.areAllFieldsSortable = collection?.areAllFieldsSortable;
        dataCollectionModal.columnApiEndpoint = collection?.columnApiEndpoint;
        dataCollectionModal.variableId = collection?.variableId;
        dataCollectionModal.returnRawData = collection.returnRawData;

        return dataCollectionModal;
    }

    const getCollectionTypeList = (sysConnectionTypeId) => {

        collectionService.getCollectionTypeList(sysConnectionTypeId)
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, collectionTypeList: response.data } });
            })
    }

    const resetErrorMessages = () => {
        setState((prevState) => {
            return {
                ...prevState, basicInfoError: null
                , columnSchemaError: null, testCollectionError: null
            }
        })
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        connectionService.getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                setSelectedConnection(res.data);
            }
        });
    }

    const handleChangeObject = () => {
        setIsOpenInDialog(false);
        props.setShowNativeDataVisualizer(false);
    }

    const getNativeCollectionParameters = async  (collectionUId) => {

        collectionService.getCollectionDetails(collectionUId)
            .then((response) => {
                if (response?.data) {
                    let prevCollection = response?.data;
                    if (prevCollection) {

                        if(prevCollection?.restDataTransformationScript){
                            setState((prevState) => { return { ...prevState, restDataTransformationScript: prevCollection?.restDataTransformationScript } });
                        }

                        if (prevCollection.collectionParameters?.length > 0){
                            const hasOtherParams = prevCollection?.collectionParameters?.some(
                                param => param.parameterTypeCD.toLowerCase() !== "fixed hidden"
                              );
                              if (hasOtherParams){
                                  let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                  setIsSearchOptionsAvailable(true);
                                  setSearchOptionsParameters(updatedParams);
                              }
                              else{
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                              }
                        }
                        else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }

                        SetNativeCollectionParameterResponse(true);
                    }
                }
            })
        
        // return false;
    }

    const updateParameterValues = (params) => {
        return params.map(param => {
            if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList && param?.complexTypeValue) {
                    const complexType = JSON.parse(param.complexTypeValue);
                    param.parameterValue = complexType?.DefaultValue
                
            }
            else if (param.sysDataTypeId == AppEnum.DataTypeId.List && param?.complexTypeValue) {
                    const complexTypeArray = JSON.parse(param.complexTypeValue);
                    const defaultItem = complexTypeArray?.find(item => item.Default === true);
                    param.parameterValue = defaultItem.Value;
                }
            else if(param.parseAtClientSide && param.sysDataTypeId === AppEnum.DataTypeId.Date){
                param.parameterValue = dateParser.parseVariable(param.defaultValue);
            }
            else {
                if(!param.variableUId && !param.isSystemDefineVariable){
                    param.parameterValue = param.defaultValue;
                }
            }
            return param;
        });
    };

    const executeData = (previewTrue = false) => {

        if (preview && !previewTrue) {
            return;
        }
        else if (preview && previewTrue) {
            setPreview(false);
        }

        setFilterColumnsList([]);
        setSearchValue('');
        if (searchInputRef?.current){
            searchInputRef.current.value = '';
        }

       
        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true } });
        resetErrorMessages();

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: state.collectionParameters,
            collectionColumns: state.collectionColumnSchema,
            uId: state.uId,
            returnFlatternData: state.sysCollectionTypeId === 7 ? !(state.returnRawData) : false,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: [],
            collectionSorts: [],
            collectionLimit: null,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : state?.returnRawData,
            restBody : state.restBody
        }

        testDataCollection(testDataCollectionModal,false);

    };

    const executedData = () => {

        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true } });
        resetErrorMessages();

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod ,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: searchOptionsParameters,
            collectionColumns: state?.collectionColumnSchema,
            uId: state.uId,
            returnFlatternData: state.sysCollectionTypeId === 7 ? !(state.returnRawData) : false,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: [],
            collectionSorts: [],
            collectionLimit: null,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : state.returnRawData,
            restBody : state.restBody
        }

        testDataCollection(testDataCollectionModal,true);

    };

    const getDisplayFormatList = () => {

        collectionService.getDisplayFormats()
            .then((response) => {

                if (response?.hasError)
                    setState((prevState) => { return { ...prevState, columnSchemaError: response.errorMessage } });

                else if (response?.data) {
                    setState((prevState) => { return { ...prevState, displayFormatList: response?.data } });
                }
            })
    }

    const handleSearchOptionsButtonClick = (index) => {
        setSelectedSearchButton(index);
    };

    //it tests the collection
     const testDataCollection = async  (testDataCollectionModal,searchButtonRefreshPreviewClicked) => {

        setState((prevState) => { return { ...prevState, isTestingConnection: true, testedDataList: [], testedDataColumnKeys:[] } });
        resetErrorMessages();

        const response = searchButtonRefreshPreviewClicked ? await collectionService.executedDataCollection(testDataCollectionModal) : await collectionService.testDataCollection(testDataCollectionModal);
    
        if(response){
            let errorMessage = '';
                if (response?.hasError){
                    errorMessage = response?.errorMessage;
                    setState((prevState) => { return { ...prevState, nativeCollectionError : errorMessage}});
                    setIsLoading(false);
                    toast.error(errorMessage);
                } 
                else if (response?.data?.errorMessage && response?.data?.data == null){
                    errorMessage = response?.data?.errorMessage;
                    setState((prevState) => { return { ...prevState, nativeCollectionError : errorMessage}});
                    setIsLoading(false);
                    toast.error(errorMessage);
                }
                else if(response?.data?.data == null && !response?.hasError && !response?.data?.errorMessage){
                    errorMessage = "The collection was tested successfully but data is not available for the given parameters.";
                    toast.error(errorMessage);
                }
                else if (response?.data?.data) {
                    if(response?.data?.statusCode == "Unauthorized"){
                            var authType = selectedConnection?.parameters.filter((i) => i.parameterType == RestServiceConstants.AUTH_TYPE)[0]?.parameterValue;
                            var grantType = selectedConnection?.parameters.filter((i) => i.parameterType == RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE)[0]?.parameterValue;
                            if(authType == "OAuth 2.0" && (grantType == "authorization_code" || grantType == "authorization_code_with_pkce")){
                                errorMessage = "Unauthorized";
                            }
                            setState((prevState) => { return { ...prevState, nativeCollectionError : errorMessage}});
                            setIsAuthorizeAgain(true);
                            setIsLoading(false);
                    }
                    else if(response?.data?.statusCode == "Forbidden"){
                        errorMessage = response?.data?.errorMessage;
                        setState((prevState) => { return { ...prevState, nativeCollectionError : errorMessage}});
                        setIsLoading(false);
                        toast.error(errorMessage);
                    }
                    else if(response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0){
                        setIsAuthorizeAgain(false);
                        errorMessage = 'The collection was tested successfully but data is not available for the given parameters.';
                        toast.success(errorMessage);
                    }
                    else{
                        setIsAuthorizeAgain(false);
                        errorMessage = 'The collection was tested successfully.';

                        let testedDataColumnKeys = null;
                        let finalData;

                        if(state.returnRawData && !Array.isArray(response?.data?.data)){
                            let rawResponse = [];
                            rawResponse.push(response?.data?.data);
                            finalData = rawResponse;
                        }else{
                            finalData = response?.data?.data;
                        }

                        if(finalData){
                            try{
    
                                if(!state.returnRawData){
                                    
                                        if (testDataCollectionModal.sysCollectionTypeId == 7){

                                            finalData = Object.keys(finalData).reduce((acc, key) => {
                                                finalData[key].forEach((value, index) => {
                                                if (!acc[index]) {
                                                    acc[index] = {};
                                                }
                                                // Skip rows with null values
                                                // if (value !== null) {
                                                    acc[index][key] = value;
                                                //}
                                                });
                                                return acc;
                                            }, []);
                                            
                                            testedDataColumnKeys = Object.keys(finalData[0] || {});
                                        }
                                        else {
                                            let maxPropsObject = {};
                                            let maxPropsCount = 0;

                                            finalData.forEach(obj => {
                                                const numProps = Object.keys(obj).length;
                                                if (numProps > maxPropsCount) {
                                                    maxPropsCount = numProps;
                                                    maxPropsObject = obj;
                                                }
                                            });
            
                                            testedDataColumnKeys = maxPropsObject;
                                            testedDataColumnKeys = (_.keys(testedDataColumnKeys));
                                        }

                                }

                                setState((prevState) => {
                                    return {
                                        ...prevState, testedDataList: finalData,
                                         testedDataColumnKeys: testedDataColumnKeys,
                                    }
                                });
                            }
                            catch(error){
                                console.log(error)
                                errorMessage = "The collection was tested successfully but data is not available for the given Data Json Path.";
                            }
                        }
                        else{
                            errorMessage = "The collection was tested successfully but data is not available for the given Data Json Path.";
                        }
                    }
                }
                setIsLoading(false);
                setErrorMessage(errorMessage,false);
        }
    }

    // sets error message
    const setErrorMessage = (errorMessage, isTestingConnection) => {
        setState((prevState) => {
            return {
                ...prevState, testCollectionError: errorMessage,
                isTestingConnection: isTestingConnection
            }
        });
    }

    const addNewDataCollection = () => {
        let dataCollectionModal = getDataCollectionModal();

        if (dataCollectionModal?.uId) {
            setIsLoading(true);
            const allColumns = state?.collectionColumnSchema?.filter(i => i.isSelected);
            const columns = allColumns.map((i) => {
                const currentColl = dataCollectionModal.collectionColumns.filter(c => c.columnName == i.columnName);
                if (currentColl.length > 0) {
                    return {
                        collectionId: currentColl[0].collectionId,
                        columnName: currentColl[0].columnName,
                        dataType: currentColl[0].dataType,
                        dataTypeName: currentColl[0].dataTypeName,
                        displayFormat: currentColl[0].displayFormat,
                        displayName: currentColl[0].displayName,
                        id: currentColl[0].id,
                        isDefault: currentColl[0].isDefault,
                        isSortable: currentColl[0].isSortable,
                        isFilterable: currentColl[0].isFilterable,
                        notQuerable: currentColl[0].notQuerable,
                        uId: currentColl[0].uId,
                        displayOrder: i.displayOrder ?? 0,
                    }
                }
                else {
                    const matchingFormat = findDisplayFormat(i.dataTypeName);
                    return {
                        ...i,
                        displayOrder: i.displayOrder ?? 0,
                        displayFormat: matchingFormat?.displayFormat ?? 'General',
                        dataType: matchingFormat?.sysDataTypeId ?? 6
                    };
                }
            })

            dataCollectionModal.collectionColumns = columns;
            // dataCollectionModal.collectionParameters = searchOptionsParameters;
            if (dataCollectionModal.isCollectionAsDataSource) {
                if(variableUId.length > 0){
                    let updateUserModel = { ...UpdateUserModel };
                    updateUserModel.uId = variableUId;
                    updateUserModel.name = dataCollectionModal.name;
                    updateUserModel.description = "";
                    updateUserModel.sysDataTypeId = 8;
                    updateUserModel.complexTypeValue = JSON.stringify(isDynamicListValues);
                    dataCollectionModal.variable = updateUserModel;
                }
            }

            if(manualColumns.length > 0){
                dataCollectionModal.collectionColumns = dataCollectionModal.collectionColumns.concat(manualColumns);
            }

            updateDataCollection(dataCollectionModal);
        }
    }

    const updateDataCollection = (dataCollectionModal) => {

        collectionService.updateDataCollection(dataCollectionModal)
            .then((response) => {
                setIsLoading(false);
                if (response?.hasError) {
                    toast.error(response?.errorMessage);
                }
                else {
                    resetErrorMessages();
                    toast.success("Collection updated successfully.");
                    if (props.showNativeDataVisualizer) {
                        handleChangeObject();
                    }
                    else {
                        window.chrome?.webview?.postMessage('close_window');
                    }
                }

            })
    }

    const findDisplayFormat = (dataTypeName) => {
        if (state?.displayFormatList.length > 0) {
            if (dataTypeName.toLowerCase() == "number") {
                dataTypeName = "Int32";
            }
            return state?.displayFormatList?.find(item => item.dataBaseDataType.toLowerCase() == dataTypeName.toLowerCase());
        }
    };


    const dataAfterTransformation = (testedDataList) => {

        let errorMessage = '';
        let transformedData = null;
        let testedDataTransformedColumnsKeys = null;

        if (testedDataList) {
            try {
                const dataTransformation = eval(`(${state.restDataTransformationScript})`);
                transformedData = dataTransformation(testedDataList);
            }
            catch (error) {
                setErrorMessage(error.message, false);
                return;
            }

            if (typeof transformedData === 'undefined') {
                setErrorMessage('The above Data Tranformation Script function did not return anything.', false);
                return;
            }

            let maxPropsObject = {};
            let maxPropsCount = 0;

            testedDataList.forEach(obj => {
                const numProps = Object.keys(obj).length;
                if (numProps > maxPropsCount) {
                    maxPropsCount = numProps;
                    maxPropsObject = obj;
                }
            });

            let collectionColumnsDataType = getDataTypeForObject(transformedData);

            testedDataTransformedColumnsKeys = transformedData[0];
            testedDataTransformedColumnsKeys = (_.keys(testedDataTransformedColumnsKeys));

            if(state?.sysCollectionTypeId === AppEnum.SysCollectionTypeId.REST){
                const restSchema = Object.keys(collectionColumnsDataType).map(key => ({
                    columnName: key,
                    columnDataType: collectionColumnsDataType[key],
                    displayName: key
                }));
                
                setRestSchemaList(restSchema);
            }

            setState((prevState) => {
                return {
                    ...prevState,
                    testedDataTransformedList: transformedData,
                    testedDataTransformedColumneKeys: testedDataTransformedColumnsKeys
                }
            });
        }

        setErrorMessage(errorMessage, false);
    }

    const getType = (value) => {
        if (value === null) {
            return AppEnum.DataBaseDataType.String;
        }
        const type = Object.prototype.toString.call(value).slice(8, -1);
        if (type === AppEnum.DataBaseDataType.Number) {
            return Number.isInteger(value) ? AppEnum.DataBaseDataType.Int : AppEnum.DataBaseDataType.Long;
        }

        if (type === AppEnum.DataBaseDataType.String) {
            // Attempt to parse the value for various data types
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                return Number.isInteger(parseFloat(value)) ? AppEnum.DataBaseDataType.Int : AppEnum.DataBaseDataType.Long;
            }
            else if (!isNaN(Date.parse(value))) {
                const parsedDate = new Date(value);
                if (!isNaN(parsedDate.getTime())) {
                    if (value.includes("T")) {
                        return AppEnum.DataBaseDataType.DateTime;
                    } else {
                        return AppEnum.DataBaseDataType.Date;
                    }
                }
            }else if (/^\d+$/.test(value)) {
                const byteValue = parseInt(value);
                if (!isNaN(byteValue) && byteValue >= 0 && byteValue <= 255) {
                    return AppEnum.DataBaseDataType.Byte;
                }
            } else if (/^\$?[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
                return AppEnum.DataBaseDataType.Currency;
            }
        }
        return type;
    };

    const getDataTypeForObject = (data) => {
        const dataTypes = {};
        const keyValues = {};
        if (!data) return;

        if (state.collectionColumnSchema) {
            let obj = data[0];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (state.collectionColumnSchema) {
                        const column = state.collectionColumnSchema.find(item => item.columnName === key);
                        dataTypes[key] = column ? column.columnDataType : getType(value);
                    }
                    else {
                        dataTypes[key] = getType(value);
                    }
                }
            }
        }
        else {
            [...data]?.reverse()?.forEach((row) => {
               
                for (const key in row) {
                    if (row.hasOwnProperty(key)) {
                        const value = row[key];
                        if (keyValues[key]) {
                            continue;
                        }
                        keyValues[key] = value;
                    }
                }
            });

            if (keyValues){
                for (const key in keyValues) {
                    if (keyValues.hasOwnProperty(key)) {
                        const value = keyValues[key];
                       
                        if (dataTypes[key]) {
                            continue;
                        }
                        dataTypes[key] = getType(value);
                    }
                }
            }
        }
        return dataTypes;
    };

    const onSearchHandler = (e) => {
        let searchedKey = e.target.value
        setSearchValue(searchedKey);
        resetErrorMessages();

        let filteredDataColunmList = [];
        let searchedColunmList = [];

        if (searchedKey) {
            filteredDataColunmList = columnsList;
            searchedColunmList = [];

            _.forEach(filteredDataColunmList, function (column) {
                if (_.includes(column?.displayName?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedColunmList.push(column);
            })
        }
        else{
            setSearchValue('');
            searchInputRef.current.value = '';
        }

        setFilterColumnsList(searchedColunmList);
    }

    const handleOnChangeSelectColumn = (event, index) => {

        if (!preview) {
            setPreview(true);
        }

        var newSelectedList = [...columnsList]
        var newFilteredList = [...filterColumnsList]
        
        let originalIndex = null;
        if (newFilteredList?.length > 0){
            const columnInFilteredList = newFilteredList[index];
            originalIndex = newSelectedList?.findIndex(i => i.columnName  === columnInFilteredList?.columnName);
        }

        if (index == null || index == undefined) {
            if (event.target.checked) {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = true;
                    return i;
                })
            }
            else {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = false;
                    return i;
                })
            }
        }
        else {
            if (originalIndex && originalIndex != null){
                newSelectedList[originalIndex].isSelected = event.target.checked;    
            }else{
                newSelectedList[index].isSelected = event.target.checked;
            }
        }

        if (newFilteredList?.length > 0) {
            if (index == null || index == undefined) {
                if (event.target.checked) {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = true;
                        return i;
                    })
                }
                else {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = false;
                        return i;
                    })
                }
            }
            else {
                newFilteredList[index].isSelected = event.target.checked;
            }
            setFilterColumnsList(newFilteredList);
        }

        let maxDisplayOrder = Math.max(...newSelectedList.filter(i => i.isSelected).map(i => i.displayOrder || 0));
        if (maxDisplayOrder !== 0){
            let increment = 1;
            newSelectedList = newSelectedList.map(i => {                
                if (i.isSelected && (i.displayOrder == undefined || i.displayOrder == null)) {
                    const updatedColumn = { ...i, displayOrder: maxDisplayOrder + increment };
                    increment++;
                    return updatedColumn;
                }
                return i;
            });
        }

        setColumnList(newSelectedList);
        setIsColumnOrderChanged(false);
        setColumnList(newSelectedList);
        setState((prevState) => { return { ...prevState, collectionColumnSchema: newSelectedList } });

    }

    const windowRef = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            if (openConnectionPopup) {
                getToken(selectedConnection, stateConnectionParameter, windowRef, setState,
                    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, executeData);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openConnectionPopup])

    const handleAuthorizeAgain = async() =>{
        authorizeAgain(setState, selectedConnection, windowRef, setOpenConnectionPopup, setStateConnectionParameter);
    }

    return (
        <>
            <ColumnSelectionPopup columnsList={columnsList}
                rowData={state?.testedDataTransformedList}
                handleOnChangeSelectColumn={handleOnChangeSelectColumn}
                handleSave={addNewDataCollection}
                executeData={executeData}
                preview={preview}
                handleChangeObject={handleChangeObject}
                isLoading={isLoading}
                isDialogOpen={isDialogOpen}
                state={state}
                setState={setState}
                selectedConnection={selectedConnection}
                onSearchHandler={onSearchHandler}
                searchInputRef={searchInputRef}
                searchValue={searchValue}
                setColumnList={setColumnList}
                setIsColumnOrderChanged={setIsColumnOrderChanged}
                handleSearchOptionsButtonClick={handleSearchOptionsButtonClick}
                selectedSearchButton={selectedSearchButton}
                isSearchOptionsAvailable={isSearchOptionsAvailable}
                searchOptionsParameters={searchOptionsParameters}
                setSearchOptionsParameters={setSearchOptionsParameters}
                executedData={executedData}
                isOpenInDialog={isOpenInDialog}
                filterColumnsList={filterColumnsList}
                handleAuthorizeAgain={handleAuthorizeAgain}
                isAuthorizeAgain={isAuthorizeAgain}
                showNativeDataVisualizer={props.showNativeDataVisualizer}/>
            {isDialogOpen &&
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={null}
                    setSelectedConnection={setSelectedConnection}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    saveCollection={addNewDataCollection}
                    isBasicInfoSubmitted={true}
                    isUpdatingCollection={true}
                    isCloudLoading={isLoading}
                />}
        </>

    )

}