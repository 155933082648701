import { executedDataCollection, testDataCollection } from "./data-collection.service";
import { toast } from "react-toastify";
import _ from 'lodash';

export async function testData(modal, setState, callBackFunc,complexTypeValue,isPopup,setPopupState,setIsLoading,isForExecutedData, setCollectionState, setIsAuthorizeAgain, setIsShowHeader) {
  const response =  isForExecutedData ? await executedDataCollection(modal) : await testDataCollection(modal);

  if (response?.hasError) {
    toast.error(response?.errorMessage);
    if (setState){
      setState(true);
    }
    if(setIsLoading){
      setIsLoading(false);
    }
    if (isPopup){
      setPopupState((prevState) => { return { ...prevState, cloudCollectionError: response, isTestingConnection: false } });
    }
  } else {
    if (
      response?.data?.statusCode === "Unauthorized" ||
      response?.data?.httpStatus === 401
    ) {
      toast.error(
        "Your connection has been expired. Please establish the connection again",
        {
          // onClick: handleToastClick,
          closeOnClick: true
        }
      );
      if(setIsLoading){
        setIsLoading(false);
      }
      if (isPopup){
        setPopupState((prevState) => { return { ...prevState, cloudCollectionError: response?.data, isTestingConnection: false } });
      }
      if (setIsAuthorizeAgain) {
        setIsAuthorizeAgain(true);
      }
      return;
    }
    else if (response?.data?.statusCode !== "OK" && response?.data?.httpStatus !== 200){
      let error = (response?.data?.data?.errorMessage || response?.data?.errorMessage);
      setCollectionState((prevState) => { return { ...prevState, testCollectionError : error} });
      return;
    }
    else if (response?.data?.errorMessage && response?.data?.data == null  && !isPopup){
      return;
    }
    else if ((response?.data?.statusCode === "Forbidden" || response?.data?.httpStatus === 403) && !isPopup){
      return;
    }

    else if(response?.data?.data && isPopup){
        setState(response?.data?.data)
    }

    if(response?.data?.data?.length === 0 || Object.keys(response?.data?.data).length === 0){
      if (setCollectionState && setIsShowHeader) {
        setIsShowHeader(true);
        setCollectionState((prevState) => {
          return {
              ...prevState, testedDataList: [],
              testedDataColumnKeys: modal?.collectionColumns,
              isTestingConnection: false
          }
      });

      dataAfterTransformation( modal?.collectionColumns,modal.restDataTransformationScript,isPopup,setPopupState, setCollectionState);
      }
    }

    
    let errorMessage = "";
    let testedDataColumnKeys = null;
    let finalData;

    if(modal?.returnRawData && !Array.isArray(response?.data?.data)){
      let rawResponse = [];
      rawResponse.push(response?.data?.data);
      finalData = rawResponse;
    }else{
        finalData = response?.data?.data;
    }

    if (finalData) {
      try {
        if (modal?.sysCollectionTypeId == 7 || (isPopup == true && !modal?.returnRawData) ) {
          finalData = Object.keys(finalData).reduce((acc, key) => {
            finalData[key].forEach((value, index) => {
              if (!acc[index]) {
                acc[index] = {};
              }
              // Skip rows with null values
              // if (value !== null) {
              acc[index][key] = value;
              //}
            });
            return acc;
          }, []);

          testedDataColumnKeys = Object.keys(finalData[0] || {});
        } else {
          if(!isPopup){
            let maxPropsObject = {};
          let maxPropsCount = 0;
          finalData.forEach((obj) => {
            const numProps = Object.keys(obj).length;
            if (numProps > maxPropsCount) {
              maxPropsCount = numProps;
              maxPropsObject = obj;
            }
          });
          testedDataColumnKeys = maxPropsObject;
          testedDataColumnKeys = _.keys(testedDataColumnKeys);
          }
        }

        if(isPopup){
            setPopupState((prevState) => {
                return {
                    ...prevState, testedDataList: finalData,
                    testedDataColumnKeys: testedDataColumnKeys,
                    isTestingConnection: false
                }
            });

            dataAfterTransformation(finalData,modal.restDataTransformationScript,isPopup,setPopupState);
        }
        else if(setCollectionState) {
          setCollectionState((prevState) => {
                return {
                    ...prevState, testedDataList: finalData,
                    testedDataColumnKeys: testedDataColumnKeys,
                    isTestingConnection: false
                }
            });
            setIsAuthorizeAgain(false)
          dataAfterTransformation(finalData,modal.restDataTransformationScript,isPopup,setPopupState, setCollectionState);
        }
      } catch (error) {
        console.log(error);
        if(isPopup){
            setPopupState((prevState) => {
                return {
                    ...prevState, 
                    testedDataList: [],
                    testedDataColumnKeys: testedDataColumnKeys,
                    isTestingConnection: false
                }
            });
        }
      }
    } else {
      errorMessage =
        "The collection was tested successfully but data is not available for the given Data Json Path.";
    }

    if(isPopup && setIsLoading){
        setIsLoading(false);
    }
    if(isPopup == null){
        var data = dataAfterTransformation(finalData,modal.restDataTransformationScript);
        if (callBackFunc) {
          callBackFunc(data, complexTypeValue);
        }
    }
  }

  return response;
}

const dataAfterTransformation = (testedDataList,script,isPopup,setPopupState, setCollectionState) => {
  let errorMessage = "";
  let transformedData = null;
  let testedDataTransformedColumnsKeys = null;

  if (testedDataList) {
    try {
      const dataTransformation = eval(`(${script})`);
      transformedData = dataTransformation(testedDataList);
    } catch (error) {
      return;
    }

    if (typeof transformedData === "undefined") {
      return;
    }

    let maxPropsObject = {};
    let maxPropsCount = 0;

    testedDataList.forEach((obj) => {
      const numProps = Object.keys(obj).length;
      if (numProps > maxPropsCount) {
        maxPropsCount = numProps;
        maxPropsObject = obj;
      }
    });

    if(isPopup){
        testedDataTransformedColumnsKeys = transformedData[0];
        testedDataTransformedColumnsKeys = (_.keys(testedDataTransformedColumnsKeys));

        setPopupState((prevState) => {
            return {
                ...prevState,
                testedDataList: transformedData,
                testedDataColumnKeys: testedDataTransformedColumnsKeys
            }
        });
    }
    else if (setCollectionState) {
        testedDataTransformedColumnsKeys = transformedData[0];
        testedDataTransformedColumnsKeys = (_.keys(testedDataTransformedColumnsKeys));

        setCollectionState((prevState) => {
            return {
                ...prevState,
                testedDataTransformedList: transformedData?.length > 100 ? transformedData?.slice(0, 100) : transformedData,
                testedDataTransformedColumneKeys: testedDataTransformedColumnsKeys
            }
        });
    }

    return transformedData;
  }
};

const handleToastClick = () => {  
  window.location.href = '/connections';
};