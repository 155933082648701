import React, { useCallback } from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "./future-connection.scss";
import { CardActionArea, CardContent, CardMedia, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ASButton } from '../as-button/as-button';
import { InputConstants } from '../../constants/input-constant';
import { ASTextField } from '../as-text-fields';
import styles from "../../styles/_exports.module.scss";

const muiCheckboxStyle = {
  color: styles.secondaryColor,
  '&.Mui-checked': {
      color: styles.checkboxColor,
  },
  '&.Mui-disabled': {
      color: 'grey',
  },
  '&.MuiFormControlLabel-label': {
      fontSize: 16,
  },
  '&.MuiSvgIcon-root':{
      fontSize : 18
  }
}

export default function FutureConnection(props) {
  const {
    isShowPopUp,
    connectorDetails,
    closeAction,
    primaryAction,
    isConnectorNotInList,
    connectorRequestData,
    handleConnectorRequestInput,
    primaryActionDisabled,
    showSuccessMessage
  } = props;

  const successMessageComponent = useCallback(() => {
    return <>
      <div className='success-message-div'>
        <img src={`/assets/success-icon.svg`}/>
        <h2>Thank You!</h2>
        <div>Your request for connector is submitted .</div>
      </div>
      <div className="button-flex">
        <ASButton onClick={() => closeAction()} primary={true}>
            Okay
        </ASButton>
      </div> 
    </> 
  },[showSuccessMessage])

  const bottomButtonComponent = useCallback((isFromConnectorNotInList,buttonDisabled) => {
    return    <div className="button-flex">
                <ASButton onClick={closeAction} primary={false} isTextButton={true} variant= {'text'}>
                  Cancel
                </ASButton>
                <ASButton disabled={isFromConnectorNotInList ? buttonDisabled : false} onClick={() => primaryAction(isFromConnectorNotInList)} primary={true}>
                    Send Request
                </ASButton>
              </div>
              },[primaryActionDisabled])
            
  
  return (
    !isConnectorNotInList ? <div>
          {
            <Dialog
              open={isShowPopUp}
              PaperProps={{ sx: { borderRadius: "10px", width: "520px",  overflow: "unset", padding:"30px" } }}
              keepMounted
              className="future-dialog-popup"
              onClose={closeAction}
            >
              {
              showSuccessMessage ?
              successMessageComponent()
              :
              <>
                <div className='future-connector-title-div'>
                  <img src={`/assets/${connectorDetails?.iconImage}`}/>
                  <label>{connectorDetails?.description}</label>
                </div>

                <div className='future-connector-content-div'>
                  <div className='future-connector-sub-title-div'>
                    <label>Request for Connector</label>
                    <div><img src={`/assets/crown.svg`}/></div>
                  </div>
                  <p>This connector will be enabled on Request</p>
                  

                  {bottomButtonComponent(false,null)}
                </div>
              </>}
            </Dialog>
          }
        </div> :

        <div>
        {
          <Dialog
            open={isShowPopUp}
            PaperProps={{ sx: { borderRadius: "10px", width: "610px",  overflow: "unset", padding:"30px" } }}
            keepMounted
            className="future-dialog-popup"
            onClose={closeAction}
          >
            {
              showSuccessMessage ?
              successMessageComponent()
              :
              <>
            <div className='connector-not-found-title-div'>
              <img src={`/assets/connector-not-found-red.svg`}/>
              <div>
                <div className='connector-not-found-header'>Connector Not Found ?</div>
                <div className='connector-not-found-dark-sub-header'>We will create your Connector on Request</div>
                <div className='connector-not-found-sub-header'>Need following information to understand your needs better</div>
              </div>
            </div>

            <div className='future-connector-content-div'>
              <div>
                {/* <div className="input-padding"> */}
                  <ASTextField
                    className="text-field"
                    type={InputConstants.text}
                    fullWidth
                    label="Name of the Application you want to connect"
                    variant="outlined"
                    value={connectorRequestData?.applicationName}
                    onChange={(event) => handleConnectorRequestInput("applicationName", event.target.value)}
                  />

                  <ASTextField
                    className="text-field"
                    type={InputConstants.text}
                    fullWidth
                    label="Application - Website if available "
                    variant="outlined"
                    value={connectorRequestData?.applicationWebsite}
                    onChange={(event) => handleConnectorRequestInput("applicationWebsite", event.target.value)}
                  />

                  <FormControlLabel control={
                      <Checkbox
                        checked={connectorRequestData?.documentationAvaiable}
                        onChange={(event) => handleConnectorRequestInput("documentationAvaiable",event.target.checked)}
                        sx={muiCheckboxStyle}
                      />
                  }
                      className='checkbox-documentation-avialable'
                      label={"Documentation Available"}
                  />

                  <ASTextField
                    className="text-field"
                    type={InputConstants.number}
                    fullWidth
                    label="No. of Users Planning to Use"
                    variant="outlined"
                    value={connectorRequestData?.numberOfUser}
                    onChange={(event) => handleConnectorRequestInput("numberOfUser", event.target.value)}
                  />

                {/* </div> */}
              </div>

              {bottomButtonComponent(true,primaryActionDisabled)}
            </div>
            </>
            }
          </Dialog>
        }
        </div>
  );
}
