import React, { useEffect, useRef, useState } from "react";
import VariableList from "../../../components/connections/variables-list/VariableList";
import * as UserService from "../../../services/variable-service";
import { Navigate } from "react-router-dom";
import _ from 'lodash';
import VariablesPopup from "./variables-Popup";
import { getCollectionDetails } from "../../../services/data-collection.service";
import { toast } from "react-toastify";
import { getDataSources } from "../../../services/data-source.service";
import { testData } from "../../../services/test-data.service";
import { sortMethod } from "../../../shared/code-challanges/code-challanges";
import { DateParseVariable } from "../../../constants/date-parse-variable";
import { AppEnum } from "../../../constants/app-enum";
import { getVariableFilterFromStorage } from "./search-or-filter-variables-controller";
import { ALL } from "../../data-collections/data-collections-controller";



class UserDefinedStateListState {
  userDefine = false;
  anchorEl = null;
  selectedRowIndex = null;
  email = "";
  users = [];
  filteredUsers = [];
}

class UsersListState {
  isUserMenuOpen = false;
  anchorEl = null;
  selectedRowIndex = null;
  users = [];
  filteredUsers = [];
}


export const getFilteredVariableList = (selectedFilter, userList, templateCompany) => {
  let filteredUsers = templateCompany
    ? userList?.filter(list => ((list.templateConnectionId === selectedFilter.id) && list?.templateConnectionId !== null))
    : userList?.filter(list => ((list.templateConnectionId === selectedFilter?.templateConnectionId) && list?.templateConnectionId !== null));

  return filteredUsers;
}


export default function Variables() {


  const [userDefinedState, setUserDefinedState] = useState(new UserDefinedStateListState());
  const [systemDefinedstate, setSystemDefinedState] = useState(new UsersListState());
  const [addNew, setAddNew] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [variableUId, setVariableUId] = useState(null);
  const [templateCompany, setTemplateCompany] = useState(undefined);
  const [connectionList, setConnectionList] = useState();
  const [valueChanged, setValueChanged] = useState(false);

  const [isShrunk, setIsShrunk] = useState(false);
  const searchInputRef = useRef('');


  const dateParser = new DateParseVariable();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await getConections();
    await getTemplateCompany();
    getSystemVariablesList();
  };

  const getTemplateCompany = async () => {
    try {
      const response = await UserService.getTemplateCompany();
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        setTemplateCompany(response?.data);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const getConections = async () => {
    try {
      const response = await getDataSources(null);
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        setConnectionList(response?.data);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const primitiveTypeValueChangeHandler = (rowData, event) => {
    setValueChanged(true);
    const singleRow = rowData;
    singleRow.primitiveTypeValue = event;
    const updatedUsers = [...userDefinedState.filteredUsers];

    updatedUsers[rowData?.uId] = {
      ...updatedUsers[rowData?.uId],
      primitiveTypeValue: event // Update the specific user's value
    };

    setUserDefinedState((prevState) => {
      return { ...prevState, filteredUsers: updatedUsers };
    });
  }

  const handleOutsideClick = (row) => {
    let model = {
      uId: row?.uId,
      isTemplateVariable: row?.isTemplateVariable,
      primitiveTypeValue: row?.primitiveTypeValue,
      sysDataTypeId: row?.sysDataTypeId,
      templateConnectionId: row?.templateConnectionId,
      name: row?.name,
      description: row?.description,
    }
    if (row?.primitiveTypeValue === "") {
      toast.error("Default Value can not be empty");
    }
    else if (valueChanged) {
      updateUserVariableApi(model, row?.uId);
    }
  }

  const updateUserVariableApi = async (model, uId) => {
    try {
      const response = await UserService.updateUserVariable(model, uId);
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        toast.success("Variable updated successfully.")
      }
      setValueChanged(false)
    } catch (error) {
      toast.error(error);
    }
  }

  //show popup
  const handleShowPopup = () => {
    setAddNew(true);
  };

  //hide popup
  const handleHidePopup = () => {
    setAddNew(false);
  };

  const handleMouseEnter = (index) => {
    setPopupVisible(index)
  };

  const handleMouseLeave = () => {
    setPopupVisible(null);
  };

  // it fetches all the users list
  const getSystemVariablesList = () => {
    UserService.getAllSystemVariables().then((response) => {
      if (response?.data) {
        const sysVariables = response?.data.map((variable) => {
          if (variable.parseAtClientSide) {
            return { ...variable, defaultValue: dateParser.parseVariable(variable.name) };
          } else {
            return variable;
          }
        })

        setSystemDefinedState((prevState) => {
          return { ...prevState, users: sysVariables, filteredUsers: sysVariables };
        });
      }
    });
  };
  // it fetches all the invitedUsers list
  const getUserDefinedListValues = (user) => {
    if (user?.dataTypeName == AppEnum.DataTypeName.List) {
      const convertedData = JSON.parse(user?.complexTypeValue);
      return convertedData;
    }
    if (user?.dataTypeName == AppEnum.DataTypeName.DynamicList) {
      return user?.complexTypeValue === null ? null : JSON.parse(user?.complexTypeValue);
    }
    return null;
  }

  useEffect(() => {
    if (connectionList && templateCompany !== undefined) {
      getUsersDefinedList();
    }
  }, [connectionList?.length, templateCompany]);


  const getUsersDefinedList = () => {
    UserService.getAllUserdefinedVariables().then((response) => {
      if (response?.data) {
        let userList = alignObject(response?.data);

        let filteredData = connectionList ? defaultFilterData(userList) : userList;

        setUserDefinedState((prevState) => {
          return { ...prevState, users: userList, filteredUsers: filteredData?.length ? filteredData : userList };
        });
      }
    });
  };


  const defaultFilterData = (userList) => {
    const currentConnectionFilter = getVariableFilterFromStorage()
    if (currentConnectionFilter && currentConnectionFilter !== ALL) {
      let selectedFilter = connectionList.find(i => i.uId === currentConnectionFilter);

      if (!Boolean(selectedFilter))
        return userList;

      return getFilteredVariableList(selectedFilter, userList, templateCompany);
    }
    else
      return null
  }


  const alignObject = (rows) => {
    const newResponse = rows?.map((row) => {
      return {
        ...row,
        complexTypeValue: getUserDefinedListValues(row),
        showList: false,
        listValues: []
      }
    })

    const updatedResponse = newResponse?.map((row) => {
      if (row?.dataTypeName === AppEnum.DataTypeName.List) {
        const updatedComplexTypeValue = row?.complexTypeValue?.find((item) => {
          if (item?.Default) {
            return item;
          }
        })
        return {
          ...row, defaultUserDefinedListValue: updatedComplexTypeValue
        };
      }
      return row;
    })
    return updatedResponse;
  }

  //routes to edit variables 

  const editVariable = (event, variable) => {
    if (event)
      event.stopPropagation();

    setVariableUId(variable.uId);
    setIsDialogOpen(true);
    closeUserMenuOption();
  };

  // it opens the choosen user permission and passes the user data to the next screen
  const editUserPermission = (user) => {
    Navigate("/users/user-permission", {
      state: user,
    });
  };



  const openConfirmationModal = (event, index) => {
    setDeleteIndex(index);
    setConfirmationModalOpen(true);
    closeUserMenuOption();
  };

  const closeConfirmationModal = () => {
    setDeleteIndex(null);
    setConfirmationModalOpen(false);
    setUserDefinedState((prevState) => {
      return {
        ...prevState,
        userDefine: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };
  // Perform delete logic here
  const confirmDelete = async () => {
    try {
      const UId = userDefinedState?.filteredUsers[deleteIndex]?.uId;
      const response = await UserService.deleteVariables(UId);
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        getUsersDefinedList();
      }
    } catch (error) {
      toast.error(error);
    }

    // Close the modal
    closeConfirmationModal();
  };

  // it opens or closes the user menu options (edit and delete)
  const openUserMenuOption = (event, userDefine, index) => {
    if (event) event.stopPropagation();
    if (userDefine) {
      setUserDefinedState((prevState) => {
        return {
          ...prevState,
          userDefine: true,
          anchorEl: event.target,
          selectedRowIndex: index,
        };
      });
    } else {
      setSystemDefinedState((prevState) => {
        return {
          ...prevState,
          isUserMenuOpen: true,
          anchorEl: event.target,
          selectedRowIndex: index,
        };
      });
    }
  };

  // it opens or closes the user menu options (edit and delete)
  const closeUserMenuOption = (event) => {
    if (event) event.stopPropagation();

    setSystemDefinedState((prevState) => {
      return {
        ...prevState,
        isUserMenuOpen: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
    setUserDefinedState((prevState) => {
      return {
        ...prevState,
        userDefine: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };

  // it deletes the particular selected user
  const deleteSelectedUser = (event, user) => {
    if (event) event.stopPropagation();
  };

  const getUserDefineVariableReportsName = (index) => {
    return userDefinedState?.filteredUsers[index]?.reportNames?.split(',');
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setVariableUId(null);
  }

  const handleAddNewVariable = () => {
    setIsDialogOpen(true)
  }
  const [showList, setShowList] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [dynamicList, setDynamicList] = useState();

  const onShowListClick = async (user) => {
    setUserDefinedState((prevState) => {
      return {
        ...prevState, filteredUsers: prevState?.filteredUsers.map((u) =>
          u?.uId === user?.uId ? { ...u, showList: true } : u
        ),
      };
    });

    setDynamicList([]);
    setActiveRow(user?.uId);
    try {
      const response = await getCollectionDetails(user?.complexTypeValue?.DataCollectionUId);
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else if (!response?.data || response?.status == 204) {
        toast.error("User do not have permission to access this Collection");
        return;
      }
      else {
        let testDataCollectionModal = {
          connectionUId: response?.data?.connectionUId,
          sysCollectionTypeId: response?.data?.sysCollectionTypeId,
          sourceName: response?.data?.sourceName,
          restRequestMethod: response?.data?.restRequestMethod,
          restRequestIsAsync: response?.data?.restRequestIsAsync,
          restBody: response?.data?.restBody,
          restSendAsCD: response?.data?.restSendAsCD,
          collectionParameters: response?.data?.collectionParameters,
          collectionColumns: response?.data?.collectionColumns,
          uId: response?.data?.uId,
          returnFlatternData: response?.data?.sysCollectionTypeId === 7 ? !(response?.data?.returnRawData) : false,
          sourceNamePart4: response?.data?.sourceNamePart4,
          restDataTransformationScript: response?.data?.restDataTransformationScript
        }
        getDynamicList(testDataCollectionModal, user?.complexTypeValue);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const getDynamicList = async (model, complexTypeValue) => {
    try {
      testData(model, setShowList, filterData, complexTypeValue);
    } catch (error) {
      toast.error(error);
    }
  }

  const filterData = (data, complexTypeValue) => {
    if (data?.length === undefined) {
      setDynamicList(data[complexTypeValue?.DisplayColumn]?.filter(value => value !== null));
    }
    else {
      let listValues = data.reduce((acc, item) => {
        const key = typeof item[complexTypeValue?.DisplayColumn] === 'boolean' ? item[complexTypeValue?.DisplayColumn]?.toString() : item[complexTypeValue?.DisplayColumn];
        const value = item[complexTypeValue?.ValueColumn];

        if (key && value) {
          acc.push({ key, value });
        }
        return acc;
      }, []);
      listValues = sortMethod(listValues, "key");
      setDynamicList(listValues);
    };
  };

  const selectDefaultValue = async (value, variable, index, listType) => {
    if (value == undefined)

      return;
    let { complexTypeValue = {} } = variable;
    if (listType == "list") {
      complexTypeValue = updateDefaultProperty(complexTypeValue, index);
      variable.defaultUserDefinedListValue = complexTypeValue[index];
    }
    else {
      complexTypeValue.DefaultValue = value;
    }
    const { listValues, ...rest } = variable;

    const updatedVariable = {
      ...rest, complexTypeValue: JSON.stringify(complexTypeValue),
    };
    await updateUserVariableApi(updatedVariable, variable?.uId)
  }

  function updateDefaultProperty(list, targetIndex) {
    return list.map((item, index) => ({
      ...item,
      Default: index === targetIndex, // Only set 'true' for the matching index
    }));
  }

  useEffect(() => {
    setUserDefinedState((prevState) => {
      return {
        ...prevState, filteredUsers: prevState?.filteredUsers.map((u) =>
          u?.uId === activeRow ? { ...u, listValues: dynamicList } : u
        ),
      };
    });
  }, [dynamicList])


  const onFocusSearchBoxHandler = () => {
    setIsShrunk(true);
  }


  const onChangeSearchSystemVariableHandler = (searchedKey) => {

    // to handle if user clears the input from close icon
    if (searchedKey === null)
      setIsShrunk(false);

    const searchKey = searchedKey?.toLowerCase().trim();

    let filteredUsers = [];
    if (searchKey) {
      filteredUsers = _.filter(systemDefinedstate.users, (variable) =>
        variable?.displayName?.toLowerCase().trim().includes(searchKey))
    }
    else {
      searchInputRef.current.value = '';
      filteredUsers = systemDefinedstate.users;
    }

    setSystemDefinedState((prevState) => ({ ...prevState, filteredUsers }));
  }

  const onChangeSearchCollectionDebouncedHandler = _.debounce(onChangeSearchSystemVariableHandler, 300)



  const onBlurSearchBoxHandler = (event) => {

    if (!event.target.value?.length)
      setIsShrunk(false);
  }

  const handleBlankDefaultValue = async (event, variable) => {
    const value = event?.target?.value;
    if(value){
      return;
    }

    let { complexTypeValue = {} } = variable;
    complexTypeValue.DefaultValue = "";

    const { listValues, ...rest } = variable;

    const updatedVariable = {
      ...rest, complexTypeValue: JSON.stringify(complexTypeValue),
    };
    await updateUserVariableApi(updatedVariable, variable?.uId)
  }



  return (
    <div>
      <VariableList
        onFocusSearchBoxHandler={onFocusSearchBoxHandler}
        onBlurSearchBoxHandler={onBlurSearchBoxHandler}
        onChangeSearchUserVariableHandler={onChangeSearchCollectionDebouncedHandler}
        searchInputRef={searchInputRef}
        isShrunk={isShrunk}
        userDefinedState={userDefinedState}
        setUserDefinedState={setUserDefinedState}
        editUserPermission={editUserPermission}
        openUserMenuOption={openUserMenuOption}
        state={systemDefinedstate}
        closeUserMenuOption={closeUserMenuOption}
        deleteSelectedUser={deleteSelectedUser}
        handleShowPopup={handleShowPopup}
        handleHidePopup={handleHidePopup}
        show={addNew}
        confirmDelete={confirmDelete}
        openConfirmationModal={openConfirmationModal}
        confirmationModalOpen={confirmationModalOpen}
        closeConfirmationModal={closeConfirmationModal}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isPopupVisible={isPopupVisible}
        getUserDefineVariableReportsName={getUserDefineVariableReportsName}
        editVariable={editVariable}
        handleAddNewVariable={handleAddNewVariable}
        onShowListClick={onShowListClick}
        showList={showList}
        activeRow={activeRow}
        dynamicList={dynamicList}
        templateCompany={templateCompany}
        connectionList={connectionList}
        primitiveTypeValueChangeHandler={primitiveTypeValueChangeHandler}
        handleOutsideClick={handleOutsideClick}
        selectDefaultValue={selectDefaultValue}
        handleBlankDefaultValue={handleBlankDefaultValue}
      />
      <VariablesPopup
        setShowList={setShowList}
        open={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        variableUId={variableUId}
        getUsersDefinedList={getUsersDefinedList}
        templateCompany={templateCompany}
        connectionList={connectionList} />
    </div>
  );
}
